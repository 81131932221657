import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);


  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper itemScope itemType="https://schema.org/LegalService"  className="flexCenter animate whiteBg" style={y > 100 ? { height: "80px" } : { height: "90px" }}>
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" style={{ textDecoration: 'none', color: 'inherit' }}  to="home" smooth={false} >
            <StyledLink className="pointer flexNullCenter" style={y > 100 ? { height:"80%" } : { height:"90%" }} to="home" smooth={false}>
              <StyledLogoIcon itemProp="logo"/>
              <TextContainer>
                <h2 itemProp="legalName" className="font18 extraBold">CAVALCANTE NETO</h2>
                <p itemProp="slogan" className="font12">Advocacia Jurídica e Administrativa</p>
              </TextContainer>
            </StyledLink>
          </Link>
          <BurderWrapper className="pointer" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurderWrapper>
          <UlWrapper itemScope itemType="http://www.schema.org/SiteNavigationElement" className="flexNullCenter">
            <li itemProp="name" className="regular font18 pointer">
              <Link itemProp="url" activeClass="active" style={{ padding: "10px 15px" }} to="home" spy={true} smooth={false} offset={-20}>
               Início
              </Link>
            </li>
            <li itemProp="name" className="regular font18 pointer">
              <Link itemProp="url" activeClass="active" style={{ padding: "10px 15px" }} to="services" spy={true} smooth={false} offset={-40}>
                Especialidades
              </Link>
            </li>
            <li itemProp="name" className="regular font18 pointer">
              <Link itemProp="url" activeClass="active" style={{ padding: "10px 15px" }} to="contact" spy={true} smooth={false} offset={-150}>
                Contato
              </Link>
            </li>
            <li itemProp="name" className="regular font18 pointer">
              <Link itemProp="url" activeClass="active" style={{ padding: "10px 15px" }} to="location" spy={true} smooth={false} offset={0}>
                Localização
              </Link>
            </li>
          </UlWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}


const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`;

const TextContainer = styled.div`
  margin-left: 15px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  h1{
    width: 100%;
    margin: 0;
  }
  p{
    margin: 0;
  }
`;

const StyledLogoIcon = styled(LogoIcon)`
  width:25%;
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
`
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;



