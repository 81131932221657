import * as React from "react";
import { ReactComponent as LogoSvg } from "../../img/velho.svg";

function SvgComponent({ fill, style, className }) {
  const customStyles = {
    fill, 
    ...style
  };

  return <LogoSvg style={customStyles} className={className} />;
}

export default SvgComponent;