import React, { useState } from 'react';
import styled, {keyframes} from 'styled-components';
import axios from 'axios';
// Assets

// Validation function
const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export default function Contact() {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [campos, setCampos] = useState({
      name: '',
      email: '',
      message: ''
  });

  function send(){
    setLoading(true); 
    axios.post('http://localhost:3030/send', campos)
    .then(response => {
       console.log("Accepted");
       setLoading(false); 
       setSubmitted(true);
      })
    .catch(error => { console.error('Error:', error.response); 
      setLoading(false); 
    });
  }

  function handleInputChange(event){
    const { name, value } = event.target;
    setCampos({ ...campos, [name]: value });
    setIsValidEmail(true);
  }

  const handleSubmit = (event) => {
    event.preventDefault(); 
    if (!validateEmail(campos['email'])) {
      setIsValidEmail(false);
    } else {
      setIsValidEmail(true);
      send(campos);
    }

  };

  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  //   // Quando o usuário começa a digitar, consideraremos o e-mail como válido até a checagem
  //   setIsValidEmail(true);
  // };

  return (
    <Wrapper  title="Contact" id="contact">
      
      <div itemScope itemType="https://schema.org/LegalService"  className="lightBg formBox">
        <div className="container">
          <HeaderInfo>
            <h2 itemProp="name" className="font40 extraBold">Consulte o especialista agora</h2>
            <h3 itemProp="description" className="font13">
              Se você está enfrentando um dilema jurídico é hora de tirar sua dúvida com um especialista. 
              <br/>
              Tire sua dúvida com um especialista agora mesmo e dê o primeiro passo na direção da resolução do seu problema. Fale conosco!
            </h3>
          </HeaderInfo>
          {!submitted && !loading &&(
          <Form onSubmit={handleSubmit}>
          <div className="contact-clean">
              <form>
                  <div className="form-group pb-2">
                    <input className="form-control" type="text" name="name" placeholder="Nome Completo" onChange={handleInputChange}/>
                  </div>
                  <div className="form-group pb-2">
                    <input
                      className={`form-control ${!isValidEmail ? 'is-invalid' : ''}`}
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleInputChange}
                    />
                    {!isValidEmail && (
                      <small className="form-text text-danger">
                        Por favor insira um email válido.
                      </small>
                    )}
                  </div>
                  <div className="form-group"><textarea className="form-control" rows="14" name="message" placeholder="Mensagem" onChange={handleInputChange}></textarea></div>
                  <div className="form-group">
                    <button className="btn btn-primary" type="submit">Enviar mensagem</button>
                  </div>
              </form>
              
          </div>
          </Form>
          )}
          {loading && (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          )}
          {submitted && !loading && (
            <SuccessMessage className="container">
              <div className="success-message text70 urbanistBig">
                Mensagem enviada com sucesso!
              </div>
            </SuccessMessage>
          )}
        </div>
      </div>
    

    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  height:700px;
  .formBox{
    height: 100%;
  }
`;
const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Or any other value that fits your design */
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const fadeInDown = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, -50px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 2rem;
  border-radius: 0.5rem;
  font-weight: bold;
  width: 50%;
  font-size: 1.5rem;
  color: #FFFFFF;
  background-color: #28a745; /* Bootstrap 'success' green */
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
  animation: ${fadeInDown} 0.5s ease-out; /* Use the animation */
`;

const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  text-align: center;
`;
// const Form = styled.form`
//   padding: 30px 0;
//   input,
//   textarea {
//     width: 100%;
//     background-color: transparent;
//     border: 0;
//     outline: none;
//     border-bottom: 1px solid #707070;
//     height: 30px;
//     margin-bottom: 30px;
//   }
//   textarea {
//     min-height: 100px;
//   }
// `;
const Form = styled.div`
  .contact-clean {
    padding:5px 0 40px;
  }

  @media (max-width:767px) {
    .contact-clean {
      padding:20px 0;
    }
  }

  .contact-clean{
    max-width:800px;
    width:90%;
    margin:0 auto;
    padding:40px;
    border-radius:4px;
    color:#505e6c;
    box-shadow:1px 1px 5px rgba(0,0,0,0.1);
  }

  @media (max-width:767px) {
    .contact-clean form {
      padding:30px;
    }
  }

  .contact-clean h2 {
    margin-top:5px;
    font-weight:bold;
    font-size:28px;
    margin-bottom:36px;
    color:inherit;
  }

  .contact-clean .form-group:last-child {
    margin-bottom:5px;
  }

  .contact-clean form .form-control {
    color: #505e6c;
    -webkit-text-fill-color: #505e6c!important;
    border-radius:2px;
    box-shadow:1px 1px 1px rgba(0,0,0,0.05);
    outline:none;
    color:inherit;
    padding-left:12px;
    height:42px;
  }

  .contact-clean form .form-control:focus {
    border:1px solid #b2b2b2;
  }

  .contact-clean form textarea.form-control {
    min-height:100px;
    max-height:260px;
    padding-top:10px;
    resize:vertical;
  }

  .contact-clean form .btn {
    background:#59473C;
    padding:16px 32px;
    border:none;
    box-shadow:none;
    text-shadow:none;
    opacity:0.9;
    text-transform:uppercase;
    font-weight:bold;
    font-size:13px;
    letter-spacing:0.4px;
    line-height:1;
    outline:none !important;
  }

  .contact-clean form .btn:hover {
    opacity:1;
  }

  .contact-clean form .btn:active {
    transform:translateY(1px);
  }

  .contact-clean form .btn-primary {
    margin-top:15px;
    color:#fff;
  }
`;  

// Cleaned up unnecessary inline styles and media queries