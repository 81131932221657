import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/img/clients/logo01.svg"
import ClientLogo02 from "../../assets/img/clients/logo02.svg";
import ClientLogo03 from "../../assets/img/clients/logo03.svg";
import ClientLogo04 from "../../assets/img/clients/logo04.svg";
import ClientLogo05 from "../../assets/img/clients/logo05.svg";
import ClientLogo06 from "../../assets/img/clients/logo06.svg";

export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true, // Adiciona o autoplay
    autoplaySpeed: 5000, // Define o intervalo de tempo para 3000ms (3 segundos)
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="d-flex flexCenter purpleBg lightColor">
          <div className="container">
            <h1 className="font20 urbanistBig bold">Atendimento Dedicado, Online e Presencial</h1>
            <p className="font15 urbanistMedium">Marque agora sua consulta e sinta a diferença no atendimento.</p>
          </div>
        </LogoWrapper>
        <LogoWrapper className="d-flex flexCenter purpleBg lightColor">
          <div className="container">
            <h1 className="font20 urbanistBig bold">Advogado Renomado com Décadas de Sucesso</h1>
            <p className="font15 urbanistMedium">Com mais de 30 anos defendendo direitos, confie na expertise individual que só uma longa carreira jurídica pode oferecer.</p>
          </div>
        </LogoWrapper>
        <LogoWrapper className="d-flex flexCenter purpleBg lightColor">
          <div className="container">
            <h1 className="font20 urbanistBig bold">Advocacia de Alto Calibre</h1>
            <p className="font15 urbanistMedium">Mais de 20 mil processos defendidos com sucesso em diversas áreas do direito.</p>
          </div>
        </LogoWrapper>
        <LogoWrapper className="d-flex flexCenter purpleBg lightColor">
          <div className="container">
            <h1 className="font20 urbanistBig bold">Cobertura Ampla, Atendimento de Excelência</h1>
            <p className="font15 urbanistMedium">Atuamos em todo o Cariri, com foco especial em Juazeiro do Norte e regiões adjacentes.</p>
          </div>
        </LogoWrapper>

      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  max-height: 500px;
  min-height: 300px;
  cursor: pointer;
  display:flex;
  text-align: center;
  width: 90%!important;
  margin: 1rem; // Espaçamento ao redor do wrapper para visualizar a sombra
  border-radius: 15px; // Curva as bordas (ajuste como necessário)
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2); // Sombra com opacidade transparente
  .container{
    width: 80%!important;
  }
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  padding: 10%;
`;