import React, { useState, useEffect } from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage1 from "../../assets/img/add/1.png";
import AddImage2 from "../../assets/img/add/2.png";
import AddImage3 from "../../assets/img/add/3.png";
import AddImage4 from "../../assets/img/add/4.png";
import WhatsAppIcon from "../../assets/svg/WhatsAppIcon";

export default function Services() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1140);

  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth >= 1140);
    }

    // Adicione o event listener
    window.addEventListener('resize', handleResize);


    handleResize();

    // Remova o event listener ao limpar
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const className = isLargeScreen ? 'flexSpaceCenter' : '';

  return (
    <Wrapper title="Services" id="services">
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container slider">
          <ClientSlider/>
        </div>
      </div>
      <div itemScope itemType="https://schema.org/LegalService" className="whiteBg" style={{ padding: "60px 0" }}>
        <div className="container">
          <HeaderInfo className={className}>
            <div>
            <h2 itemProp="name" className="font70 extraBold urbanistBig">Especialidades</h2>
            <h3 className="font25 urbanistMedium">
              Descubra a excelência em serviços jurídicos,
              <br />
              personalizados para todas as suas necessidades.
            </h3>
            </div>
            <ContactButton itemProp="link" href="https://wa.me/5588994243202?text=Olá%2C%20estou%20interessado%20em%20seus%20serviços" target="_blank"  className='font30'>
              <div className="whatsicon">
                <WhatsAppIcon color="#fff" width="32" height="32"></WhatsAppIcon>
              </div>
              Fale agora com um especialista
            </ContactButton>
          </HeaderInfo>
          <ServiceBoxRow itemProp="hasOfferCatalog" itemScope itemType="https://schema.org/OfferCatalog" className="flex">
            <ServiceBoxWrapper itemProp="itemListElement" itemScope itemType="https://schema.org/Offer" >
              <ServiceBox
                itemProp="itemOffered"
                itemScope itemType="https://schema.org/Service"
                icon="old"
                title="Direito Previdenciário"
                subtitle="Lidando com a complexidade da aposentadoria ou outros benefícios previdenciários? Permita-nos orientá-lo através dos desafios burocráticos para assegurar seus direitos e propiciar a tranquilidade que você merece."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper itemProp="itemListElement" itemScope itemType="https://schema.org/Offer" >
              <ServiceBox
                itemProp="itemOffered"
                itemScope itemType="https://schema.org/Service"
                icon="free"
                title="Direito Trabalhista"
                subtitle="Desafios trabalhistas exigem um defensor resiliente. Seja demissão injusta, direitos negados ou disputas legais, encontre aqui o suporte jurídico que luta tenazmente pelo que você merece no ambiente de trabalho."
              />
            </ServiceBoxWrapper>
            <ServiceBoxWrapper itemProp="itemListElement" itemScope itemType="https://schema.org/Offer" >
              <ServiceBox
                itemProp="itemOffered"
                itemScope itemType="https://schema.org/Service"
                icon="bank"
                title="Direito Bancário"
                subtitle="Confronte tarifas excessivas e situações adversas com bancos com um especialista ao seu lado. Defenda-se de contratos leoninos e cobranças injustas, preservando seu patrimônio e tranquilidade."
              />
            </ServiceBoxWrapper>
          </ServiceBoxRow>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;


const ServiceBoxRow = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 20%;
  margin-right: 5%;
  padding: 80px 0;
  @media (max-width: 860px) {
    width: 100%;
    text-align: center;
    padding: 40px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const ContactButton = styled.a`
  display: inline-flex;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  background-color: #46D710;
  font-weight: bold;
  transition: background-color 0.3s;
  .whatsicon{
    margin-right: 10px;
  }
  &:hover {
    background-color: #46D710;
    background-image: linear-gradient(180deg, #46D710 10%, #0C811C 100%);
  }
`;
