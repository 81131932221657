import React from "react";
import { Routes, Route} from "react-router-dom";
import Landing from "../screens/Landing";
import { useEffect } from 'react';

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    // Isso redirecionará o usuário para a URL especificada em "to"
    window.location.href = to;
  }, [to]);

  return null; // Retorna null pois o componente não precisa renderizar nada.
};
// Importe outros componentes de tela conforme necessário

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/reuniao/presencial" element={<ExternalRedirect to="https://cal.com/cavalcante/presencial" />} />
      <Route path="/reuniao/60min" element={<ExternalRedirect to="https://cal.com/cavalcante/60min" />} />
      <Route path="/reuniao/30min" element={<ExternalRedirect to="https://cal.com/cavalcante/30min" />} />
      <Route path="/reuniao/audiencia" element={<ExternalRedirect to="https://cal.com/cavalcante/audiencia" />} />
    </Routes>
  );
}