import React from "react";
import { Helmet } from "react-helmet";
// Screens
import 'bootstrap/dist/css/bootstrap.min.css';
import Landing from "./screens/Landing.jsx";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";

export default function App() {
  return (
    <Router>
      <>
        <Helmet>
          <title>Cavalcante Neto | Advocacia Jurídica e Administrativa</title>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <meta name="keywords" content="CAVALCANTE NETO ADVOCACIA" />
          <meta property="og:title" content="Cavalcante Neto | Advocacia Jurídica e Administrativa" />
          <meta name="description" content="Cavalcante Neto Advogado especialista em Direito Trabalhista e Bancário em Juazeiro do Norte. Consultoria em recisão de trabalho, fraudes bancárias e juros abusivos."></meta>
          <meta property="og:image" content="https://www.meuvademecumonline.com.br/blog/wp-content/uploads/2023/03/o-que-e-direit-trabalhista.jpg" />
          <meta property="og:url" content="https://advogadocavalcante.com.br/" />
          <meta name="twitter:card" content="summary_large_image" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="anonymous" />
          <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
          <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "LegalService",
            "@id": "http://advogadocavalcante.com.br/#legalService",
            "address": {
              "@type": "PostalAddress",
              "addressCountry": "BR",
              "addressLocality": "Juazeiro Do Norte",
              "addressRegion": "CE",
              "postalCode": "58046700",
              "streetAddress": "Rua Edgar Coelho de Alencar, 198",
              "description": "Localiza-se em Juazeiro Do Norte no estado do Ceará, região conhecida como Cariri, e oferece atendimento especializado em legislação trabalhista e bancária."
            },
            "description": "Cavalcante Neto Advocacia é um escritório de advocacia com especialidades em Direito Trabalhista e Direito Bancário, oferecendo orientação e assistência jurídica completa para questões complexas nestas áreas.",
            "email": "rcavalcanten@gmail.com",
            "logo": "https://advogadocavalcante.com.br/static/media/logocavalcante.7b283ea37ea297cea08adc9dbce04cc2.svg",
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": [
                  "Tuesday",
                  "Wednesday",
                  "Thursday"
                ],
                "opens": "08:00",
                "closes": "14:00"
              }
            ],
            "url": "https://advogadocavalcante.com.br",
            "legalName": "Cavalcante Neto Advocacia",
            "name": "Cavalcante Neto Advogado",
            "slogan": "Compromisso com a excelência na defesa dos seus direitos bancários e trabalhistas.",
            "telephone": "+55-88-99999-9999",
            "founder": "Raimundo Cavalcante Neto",
            "foundingDate": "2015-08-04",
            "hasMap": "https://maps.google.com/?cid=17958995928347131481", // Substituir pelo link correto do Google Maps
            "areaServed": "Cariri e regiões adjacentes",
            "languagesSpoken": "Portuguese",
            "serviceArea": [
              {
                "@type": "City",
                "name": "Juazeiro Do Norte"
              },
              {
                "@type": "State",
                "name": "Ceará"
              }
            ]
          })}
          </script>
        </Helmet>
        <AppRoutes />
      </>
    </Router>
  );
}

