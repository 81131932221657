import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon from "../../assets/svg/LogoCom";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <div itemScope itemType="https://schema.org/LegalService" >
        <SidebarHeader className="flexCenter">
          <div className="flexCenter">
            <StyledLogoIcon itemProp="logo" fill="#fff" />
          </div>
        </SidebarHeader>
        <UlStyle itemScope itemtype="http://www.schema.org/SiteNavigationElement" className="flexNullCenter flexColumn">
          <li itemProp="name" className="semiBold font18 pointer">
            <Link itemProp="url"
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="home"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Início
            </Link>
          </li>
          <li itemProp="name" className="semiBold font18 pointer">
            <Link itemProp="url"
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="services"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Especialidades
            </Link>
          </li>
          <li itemProp="name" className="semiBold font18 pointer">
            <Link itemProp="url"
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="contact"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Contato
            </Link>
          </li>
          <li itemProp="name" className="semiBold font18 pointer">
            <Link itemProp="url"
              onClick={() => toggleSidebar(!sidebarOpen)}
              activeClass="active"
              className="whiteColor"
              style={{ padding: "10px 15px" }}
              to="location"
              spy={true}
              smooth={true}
              offset={-60}
            >
              Localização
            </Link>
          </li>
        </UlStyle>
        <SidebarHeader className="flexCenter">
          <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
            <CloseIcon />
          </CloseBtn>
        </SidebarHeader>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-400px")};
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const StyledLogoIcon = styled(LogoIcon)`
  width:70%;
  fill: ${props => props.fill || 'currentColor'};
`;

const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
