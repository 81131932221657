import React from "react";
import styled from "styled-components";

export default function Location() {
  // Substitua o src do iframe pela URL do local específico que você deseja exibir no Google Maps
  const locationSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d455.6816982602875!2d-39.34604297113864!3d-7.229140238783404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7a1825c7a8cb10d%3A0x4f92f144e57be43f!2sR.%20Edgar%20Coelho%20de%20Alencar%2C%20198%20-%20S%C3%A3o%20Jos%C3%A9%2C%20Juazeiro%20do%20Norte%20-%20CE%2C%2063024-440!5e0!3m2!1spt-PT!2sbr!4v1704224748143!5m2!1spt-PT!2sbr";

  return (
    <Wrapper title="Location" id="location">
      <div>
        <div className="whiteBg">
          <div className="container">
            <MapBox>
              <iframe
                title="location"
                src={locationSrc}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              />
            </MapBox>
            <div title="Address" className="flexCenter mb-5">
              <p className="font13 urbanistBig text-center">
                <span > Rua Edgar Coelho de Alencar, 198</span> - São José, <span>Juazeiro do Norte - CE</span>, <span>63024-440</span>
                <br />
                <span >Escritório de Advocacia Cavalcante Neto, especializado em Direito Trabalhista e Direito Bancário.</span>
              </p>
            </div>
          
          </div>

        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  display: grid;
  align-items: center;
  min-height: 90vh;
`;

const MapBox = styled.div`
  width: 100%;
  margin: 100px 0 50px 0;
  height: 400px; // Pode configurar de acordo com o necessário
  iframe {
    width: 100%;
    height: 100%;
  }
`;