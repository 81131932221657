import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
// Assets
import HeaderImage from '../../assets/img/header-img-web.webp';
import HeaderImageMobile from '../../assets/img/header-img-web-mobile.webp';
import HeaderImageTablet from '../../assets/img/header-img-web-tablet.webp';
import PngOverlayImage from '../../assets/img/themis.webp';
import WhatsAppIcon from '../../assets/svg/WhatsAppIcon';

export default function Header() {
  const parallaxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Ajustar 'speed' para mudar a intensidade do efeito parallax
      const speed = 0.5;
      // Atualiza a posição da layer com base na posição de scroll atual
      if(parallaxRef.current) {
        const offset = scrollPosition * speed;
        // Altera o estilo 'transform' do elemento para criar o efeito parallax
        parallaxRef.current.style.transform = `translateY(${offset}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper title="Header" id="home">
      <div>
        <Background> 
          <source media="(max-width: 720px)" srcSet={HeaderImageMobile} />
          <source media="(max-width: 960px)" srcSet={HeaderImageTablet} />
          <img src={HeaderImage} alt="Header background" loading='lazy'/>
        </Background>
        <div className='container'>
          <ContentContainer>
            <h1 className="font40 urbanistMedium">
                Especialista em <b className='urbanitBig yellowColor'>Direito Bancário</b> e <b itemProp="keywords" className='urbanitBig yellowColor'>Direito Trabalhista</b>
            </h1>
            <h2 className='font25 urbanistThin'>
              Orientação jurídica <b>especializada</b> e <b>soluções estratégicas</b> para enfrentar <b>desafios</b> legais em questões <b>bancárias</b> e de <b>relações de trabalho</b>.
            </h2>
            <ContactButton href="https://wa.me/5588994243202?text=Olá%2C%20estou%20interessado%20em%20seus%20serviços" target="_blank"  className='darkBg  font30'>
              <div  className="whatsicon">
                <WhatsAppIcon alt="whatsapp" color="#fff" width="32" height="32"></WhatsAppIcon>
              </div>
              Receba orientação
            </ContactButton>

          </ContentContainer>
        </div>
        <BalanceLayer ref={parallaxRef} src={PngOverlayImage} alt="Themis" loading="lazy"/>
        <FloatButton href="https://wa.me/5588994243202?text=Olá%2C%20estou%20interessado%20em%20seus%20serviços" target="_blank"  className='darkBg  font30'>
          <div className="whatsicon">
            <WhatsAppIcon alt="whatsapp" color="#fff" width="32" height="32"></WhatsAppIcon>
          </div>
        </FloatButton>
      </div>
    </Wrapper>
    
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 960px;
  overflow: hidden;
  @media (max-width: 960px) {
    min-height: 800px; // nova altura mínima para tablets
  }

  @media (max-width: 720px) {
    min-height: 720px; // nova altura mínima para dispositivos móveis
  }

  .container{

    @media (max-width: 960px) {
      display: flex;  
      justify-content: center;
    }
  }
`;

const FloatButton = styled.a`
  position: fixed;
  width: 60px;
  height: 60px; // Height of button
  bottom: 20px; // Distance from bottom of viewport
  right: 20px; // Distance from right of viewport
  background-color: #25D366; // WhatsApp color or any color you prefer
  border-radius: 50%; // Circular shape
  display: flex;
  justify-content: center; // Center the icon horizontally
  align-items: center; // Center the icon vertically
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); // Simple drop shadow for better visibility
  z-index: 999; // Ensure it's on top of other elements
  text-decoration: none; // Removes underline from the link

  &:hover {
    background-color: #1ebe5f; // Slightly darker green on hover
  }

  .whatsicon{
    display: flex;
    align-items: center;
  }
`;

const Background = styled.picture`
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  img {
    @media (min-width: 1080px) {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 1080px) {
      height: 100%;
    }
    
  }
  ::after { 
    content: ''; 
    position: absolute; 
    top: 0; 
    left: 0; 
    right: 0; 
    bottom: 0; 
    background: rgba(38, 38, 38, 0.8); 
     z-index: 1; 
  }
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 500px; // Ajustando a altura mínima para tamanhos menores de tela
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  max-width: 600px;
  margin: 0 20px;
  .title{
    font-family: 'Urbanist';
    font-weight: 100;
  }
  color: #fff; // A cor do texto pode variar de acordo com o seu design
  @media only screen and (max-width: 720px) {
    text-align: center;
    top: 40%;
  }

`;


const ContactButton = styled.a`
  display: inline-flex;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;

  font-weight: bold;
  transition: background-color 0.3s;
  .whatsicon{
    margin-right: 10px;
  }
  &:hover {
    background-color: #46D710;
    background-image: linear-gradient(180deg, #46D710 10%, #0C811C 100%);
  }
`;

const BalanceLayer = styled.img`
  position: absolute;
  top: 0;
  left: 30vw;
  height: 110%;
  z-index: 2;
  @media (max-width: 960px) {
    justify-content: center;
    align-items: center; 
    left: -10vw;
  }
  @media (max-width: 720px) {
    display:none;
  }
`;