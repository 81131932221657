import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import AddImage2 from "../../assets/img/add/add2.png";
import WhatsAppIcon from "../../assets/svg/WhatsAppIcon";

export default function Projects() {
  return (
    <Wrapper title="Description" id="projects">
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">

            <AddLeft>
              <AddLeftInner>
                <h4 className="font15 urbanistMedium">Um Olhar Jurídico,</h4>
                <h2 iteclassName="font80 extraBold urbanistBig">Raimundo Cavalcante Neto</h2>
                <p className="font18 urbanistMedium">
                Com a experiência de três décadas na advocacia e inscrito sob OAB/CE 8491, é um defensor apaixonado e comprometido com a proteção dos direitos fundamentais. Especializado em <b>Direito Trabalhista</b> e <b>Direito Bancário</b>, ele encarna a mistura perfeita entre a sabedoria acumulada e a energia necessária para lutar fervorosamente pela justiça.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <div style={{ width: "100%" }}>
                  <ContactButton href="https://wa.me/5588994243202?text=Olá%2C%20estou%20interessado%20em%20seus%20serviços" target="_blank"  className='font30'>
                    <div className="whatsicon">
                      <WhatsAppIcon color="#fff" width="32" height="32"></WhatsAppIcon>
                    </div>
                    Receba orientação
                  </ContactButton>
                  </div>
                </ButtonsRow>
              </AddLeftInner>
            </AddLeft> 
            <AddRight>
                <ImgWrapper className="flexCenter">
                  <img className="radius8" src={AddImage2} alt="profile" />
                </ImgWrapper>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  top:10px;
  position: relative;
  left: 0;
  p {
    max-width: 800px;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const ContactButton = styled.a`
  display: inline-flex;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  background-color: #46D710;
  font-weight: bold;
  transition: background-color 0.3s;
  .whatsicon{
    margin-right: 10px;
  }
  &:hover {
    background-color: #46D710;
    background-image: linear-gradient(180deg, #46D710 10%, #0C811C 100%);
  }
`;

