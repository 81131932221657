import React, { useEffect, useState }  from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoIcon from "../../assets/svg/LogoLg";
export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="animate pointer flexNullCenter" style={{ textDecoration: 'none', color: 'inherit' }}  to="home" smooth={false} >
              <StyledLink className="pointer" to="home" smooth={false}>
                <StyledLogoIcon fill="#fff"/>
              </StyledLink>
            </Link>
            {/* <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <LogoImg />
              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Fanatic
              </h1>
            </Link> */}
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="purpleColor font13">Cavalcante Neto Advogado</span> Todos os direitos reservados.
            </StyleP>

            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Voltar ao topo
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;



const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
`;

const StyledLogoIcon = styled(LogoIcon)`
  height: 50px;
`;
