import React from "react";
import styled from "styled-components";
// Assets
import BankIcon from "../../assets/svg/Services/BankIcon";
import FreeIcon from "../../assets/svg/Services/FreeIcon";
import OldIcon from "../../assets/svg/Services/OldIcon";

export default function ServiceBox({icon, title, subtitle}) {
  let getIcon;

  switch (icon) {
    case "free":
      getIcon = <FreeIcon alt="jailicon" itemProp="image" style={{ width: '70px', height: '70px'}}/>;
      break;
    case "old":
      getIcon = <OldIcon alt="oldicon" itemProp="image" style={{ width: '70px', height: '70px'}}/>;
      break;
    case "bank":
      getIcon = <BankIcon alt="bankicon" itemProp="image" style={{ width: '70px', height: '70px'}}/>;
      break;
    default:
      getIcon = <FreeIcon alt="jailicon" itemProp="image" style={{ width: '70px', height: '70px'}}/>;
      break;
  }


  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font25 urbanistBig extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font18 urbanistMedium">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  width: 70px!important;
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;

const TitleStyle = styled.h2`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 18px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.h3`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;